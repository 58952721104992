import React from "react";
import { ZoomImageUrl } from "../types/images";
import { Button } from "./Button";
import cn from "classnames";
import { useZoomImage } from "../hooks/useZoomImage";

type Props = {
  className?: string;
  zoomImage?: ZoomImageUrl;
};

export const ZoomImage: React.FC<Props> = ({
  className,
  zoomImage,
  children,
}) => {
  const showZoom = useZoomImage(zoomImage);

  return (
    <Button
      variant="none"
      className={cn(!!zoomImage && "zoom-in", className)}
      onClick={showZoom}
      onKeyPress={showZoom}
    >
      {children}
    </Button>
  );
};
