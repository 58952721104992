import React from "react";
import { ZoomImage } from "../ZoomImage";

type Props = React.ImgHTMLAttributes<HTMLImageElement>;

function getImgOriginalUrl(props: Props) {
  //console.log("getImgOriginalUrl", props);

  if (!props.srcSet) return props.src;

  const split = Array.isArray(props.srcSet)
    ? props.srcSet
    : props.srcSet.split(",");

  if (!split.length) return props.src;

  const last = split[split.length - 1];
  return last.substring(0, last.lastIndexOf(" "));
}

export const ImgZoom: React.FC<Props> = (props) => {
  const zoomImage = getImgOriginalUrl(props);

  return (
    <ZoomImage zoomImage={zoomImage}>
      <img {...props} />
    </ZoomImage>
  );
};
