import React from "react";
import Slugger from "github-slugger";
import { Link } from "../Link";
import { MdxHeading } from "../../types/mdx";
import * as styles from "./TableOfContents.module.scss";

type Props = { headings: MdxHeading[]; maxDepth?: number };

export const TableOfContents: React.FC<Props> = ({
  headings,
  maxDepth = 3,
}) => {
  if (!headings || headings.length < 2) return null;

  let start = 0;

  const slugger = new Slugger();

  const renderList = (parent: number) => {
    const el = [];
    while (start < headings.length) {
      const { depth, value } = headings[start];
      if (depth <= parent) break;

      start++;

      if (depth > maxDepth) continue;

      const slug = slugger.slug(value);

      el.push(
        <li key={start}>
          <Link to={"#" + slug}>{value}</Link>
          {renderList(depth)}
        </li>
      );
    }
    if (!el.length) return null;

    return <ul className="list">{el}</ul>;
  };

  const list = renderList(1);
  if (!list) return null;
  return (
    <div className={styles.toc}>
      <h3>Table of Contents</h3>
      {list}
    </div>
  );
};
