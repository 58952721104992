import React, { TableHTMLAttributes } from "react";
import { MDXRenderer, MDXRendererProps } from "gatsby-plugin-mdx";
// @ts-ignore
import { MDXProvider } from "@mdx-js/react";
import { ImgZoom } from "./ImgZoom";
import { Link } from "../Link";
import { TableOfContents } from "./TableOfContents";
import { CookieWall } from "../CookieWall";
import { ImgHTMLAttributes } from "react";
import { AnchorHTMLAttributes } from "react";

export const mdxComponents = {
  CookieWall,
  TableOfContents,
  img: (props: ImgHTMLAttributes<HTMLImageElement>) => <ImgZoom {...props} />,
  a: ({ href, ...rest }: AnchorHTMLAttributes<HTMLAnchorElement>) => (
    <Link to={href || ""} {...rest} />
  ),
  table: (props: TableHTMLAttributes<HTMLTableElement>) => (
    <div className="scroll-x">
      <table {...props} />
    </div>
  ),
  ol: (props: React.OlHTMLAttributes<HTMLOListElement>) => (
    <ol className="list" {...props} />
  ),
  ul: (props: React.OlHTMLAttributes<HTMLUListElement>) => (
    <ul className="list" {...props} />
  ),
};

type Props = MDXRendererProps;

export const MdxRenderer: React.FC<Props> = (props) => {
  return (
    <MDXProvider components={mdxComponents}>
      <MDXRenderer {...props} />
    </MDXProvider>
  );
};
