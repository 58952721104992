import React from "react";
import {
  useCookieConsent,
  useShowCookiePreferences,
} from "./Layout/CookieContainer";
import {
  getUrlCookies,
  hasCookieConsent,
  mergeCookies,
} from "../utils/cookies";
import { Link } from "./Link";
import { useCallback } from "react";
import * as styles from "./CookieWall.module.scss";

type Props = {
  altUrl?: string;
  alt?: React.ReactNode;
  cookies?: string[];
};

export const CookieWall: React.FC<Props> = ({
  cookies,
  alt,
  altUrl,
  children,
}) => {
  const { consent } = useCookieConsent();
  const showCookiePreferences = useShowCookiePreferences();

  const handlePreferences = useCallback(
    (e) => {
      e.preventDefault();
      showCookiePreferences();
    },
    [showCookiePreferences]
  );

  if (hasCookieConsent(consent, mergeCookies(cookies, getUrlCookies(altUrl))))
    return <>{children}</>;

  if (alt !== undefined) return <>{alt}</>;

  return (
    <div className={styles.wall}>
      <p>
        Embedded content was blocked by your{" "}
        <Link to="#" onClick={handlePreferences}>
          Privacy Preferences
        </Link>
        .{" "}
        {!!altUrl && (
          <>
            Feel free to{" "}
            <Link ext to={altUrl}>
              view it externally
            </Link>
            .
          </>
        )}
      </p>
    </div>
  );
};
